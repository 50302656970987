<template>
  <div class="role-list">
    <div class="outer">
      <search-panel
        show-btn
        flex
        @getList="getList"
      >
        <div>
          <p>用户名称/手机号</p>
          <el-input
            v-model="userName"
            clearable
            placeholder="请输入用户名称/手机号"
            size="mini"
          />
        </div>
        <div>
          <p>真实姓名</p>
          <el-input
            v-model="realname"
            clearable
            placeholder="请输入真实姓名"
            size="mini"
          />
        </div>
        <div>
          <p>创建开始日期</p>
          <el-date-picker
            v-model="tagtime"
            size="mini"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          />
        </div>
        <div>
          <p>状态</p>
          <el-select
            v-model="accountStatus"
            size="mini"
            clearable
            @change="getList"
          >
            <el-option
              v-for="(item,index) in accountStatusArr"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-button
          slot="button-right"
          type="primary"
          size="mini"
          @click="operation('add')"
        >
          新 增
        </el-button>
      </search-panel>

      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="multipleSelectShow"
          type="selection"
          width="55"
        />
        <el-table-column
          prop="userMobile"
          label="用户名称"
        />
        <el-table-column
          prop="userRealName"
          label="角色名称"
          width="120"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="120"
        />
        <el-table-column
          prop="status"
          label="状态"
          width="130"
        >
          <template slot-scope="scope">
            {{ ['启用','禁用'][Number(scope.row.userState)] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作"
          width="250"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="scope.row.userState"
                type="success"
                size="mini"
                @click="disableOperation('enable', scope.row)"
              >
                启用
              </el-button>
              <el-button
                v-if="!scope.row.userState"
                type="warning"
                size="mini"
                @click="disableOperation('disable', scope.row)"
              >
                禁用
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="operation('edit', scope.row)"
              >
                编辑
              </el-button>
              <!-- <el-button type="danger" size="mini" @click="deleteOperation('delete',scope.row)">删除</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { confirm } from '@/assets/js/confirmUrl';

export default {
  name: 'role-list',
  data() {
    return {
      userName: '',
      realname: '',
      tableData: [],
      loading: false,
      createStartTime: null,
      createEndTime: null,
      tagtime: [],
      roleName: '', // 角色名称
      roleNameArr: [
        {
          label: '全部',
          value: -1,
        },
        {
          label: '启用',
          value: 0,
        },
        {
          label: '禁用',
          value: 1,
        },
      ], // 角色状态
      multipleSelection: [], // 多选
      multipleSelectShow: false, // 多选控制器
      serviceStatus: '', // 是否是服务商
      serviceStatusArr: [
        {
          label: '全部',
          value: -1,
        },
        {
          label: '是',
          value: 0,
        },
        {
          label: '否',
          value: 1,
        },
      ],
      // 账号状态
      accountStatus: '',
      accountStatusArr: [
        {
          label: '启用',
          value: 0,
        },
        {
          label: '禁用',
          value: 1,
        },
      ],
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
    };
  },
  watch: {
    tagtime(item) {
      if (item) {
        this.createStartTime = item[0];
        this.createEndTime = item[1];
      } else {
        this.createStartTime = null;
        this.createEndTime = null;
      }
    },
  },
  mounted() {
    this.getList();
    this.getRoleList();
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.admin.list,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          userMobile: this.userName ? this.userName : null,
          userRealName: this.realname ? this.realname : null,
          startTime: this.createStartTime ? this.createStartTime : null,
          endTime: this.createEndTime ? this.createEndTime : null,
          userState: this.accountStatus,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    // 禁用和启用
    disableOperation(type, row) {
      console.log('row', row);
      this.$confirm('确定修改此账号状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (type === 'disable') {
          this.$axios({
            method: 'post',
            url: this.$api.admin.disable,
            data: {
              userId: row.userId,
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        } else {
          this.$axios({
            method: 'post',
            url: this.$api.admin.enable,
            data: {
              userId: row.userId,
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    // 删除
    deleteOperation(type, row) {
      this.$confirm('确定删除此账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios({
          method: 'post',
          url: this.$api.admin.delete,
          data: {
            adminId: Number(row.adminId),
          },
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.getList();
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    operation(type, row) {
      if (type === 'add') {
        this.$router.push('/nb/setting/account/detail?type=add');
      } else if (type === 'edit') {
        console.log(row);
        this.$router.push({ path: '/nb/setting/account/detail', query: { type, id: row.userMobile } });
      }
    },
    // 状态改变
    changeState(item) {
      this.orderStatus = item.name;
      this.getListChange();
    },
    getRoleList() {
      this.$axios({
        method: 'post',
        url: this.$api.role.list,
        data: {
          currentPage: 1,
          pageSize: 100,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.roleNameArr = res.data.records;
        } else {
          this.$message(res.error_msg);
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log('多选', this.multipleSelection);
    },
    // 提交删除
    multipleDelSubmit() {
      this.toDelete();
    },
  },
};
</script>

<style scoped lang="scss">
</style>
